<template>
  <v-container
    fluid
    class="pa-0 ma-0"
  >
    <title-block title="常见问题" />
    <v-expansion-panels
      accordion
      focusable
      class="mb-4 mb-sm-8 pt-4 pt-sm-8"
    >
      <v-expansion-panel key="problem1">
        <v-expansion-panel-header class="grey darken-4 white--text">
          本次活动的防疫政策有哪些要求？
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-5 mb-2">
          <div>① 参加线下会议的同学，请持72小时内核酸检测报告，为了您和他人的防疫安全，并全程佩戴口罩。</div>
          <div>② 到达现场后，需配合工作人员体温检测并扫健康码，无异常后方可入内参会。</div>
          <div>③ 到达4楼后请及时入座，避免人员聚集，社交请保持1米左右安全距离。</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      accordion
      focusable
      class="mb-4 mb-sm-8 mb-2 mb-sm-4"
    >
      <v-expansion-panel key="problem1">
        <v-expansion-panel-header class="grey darken-4 white--text">
          怎么参加本次线下活动，报名成功后如何取票？
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-5 mb-2">
          点击立即报名，填写报名信息，等待主办方审核通过后即可参加线下活动。审核结果将以短信和邮件形式发送，用户在活动现场直接显示审核信息中的二维码签到即可。
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      accordion
      focusable
      class="mb-4 mb-sm-8 mb-2 mb-sm-4"
    >
      <v-expansion-panel key="problem2">
        <v-expansion-panel-header class="grey darken-4 white--text">
          没有办法参加线下活动，线上会有同步直播吗？
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-5 mb-2">
          我们非常期待您能参加本次线下活动，众多行业大咖分享前沿经验，这是一次难得的交流学习机会，也是帮助您拓展人脉的好时机。 如果您不能来参加线下会议，我们线上也会同步进行直播，可以关注网站进行观看。
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      accordion
      focusable
      class="mb-4 mb-sm-8 mb-2 mb-sm-4"
    >
      <v-expansion-panel key="problem3">
        <v-expansion-panel-header class="grey darken-4 white--text">
          活动现场是否提供午餐，下午五个分会场是同时进行的吗？
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-5 mb-2">
          参加线下活动的小伙伴，报名时请勾选是否需要午餐，活动现场签到后我们将发放免费午餐券。下午五个分会场同时进行，同一时段只能参加一个会场，但是允许串场。
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels
      accordion
      focusable
      class="mb-4 mb-sm-8 mb-6 mb-sm-16"
    >
      <v-expansion-panel key="problem4">
        <v-expansion-panel-header class="grey darken-4 white--text">
          峰会结束后，请问老师的直播或PPT是否有回放及下载？
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-5 mb-2">
          本次峰会结束后，我们将会把每场直播剪辑后上传网站，PPT征得嘉宾同意后也会同步上传，您可以进行回放观看或者下载。
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
<script>
import TitleBlock from '@/views/components/TitleBlock.vue'
export default {
  name: 'IndexProblems',
  components: {
    TitleBlock
  }
}
</script>
<style lang="sass" scoped>
.intro-text
  line-height: 40px
  text-indent: 36px
</style>